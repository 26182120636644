import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SecurityComponent from "./Login/SecurityComponent";


const App = () => (
  <Router>
    <SecurityComponent />
  </Router>
);

export default App;