import React from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom"; // Updated for React Router v6
import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
import { Login } from "./Login";
import Loading from '../components/Elements/Loading';
import AdminComponent from "../components/AdminConsole/AdminComponent";
import CustomHeader from "../components/Header/CustomHeader";
import { Header } from "antd/es/layout/layout";
import MainPage from "../components/MainPage/MainPage";
import "./style.css";
import Audit from "../components/AdminConsole/Audit";

const oktaAuth = new OktaAuth({
  issuer: "https://login.au10tix.com/oauth2/default",
  clientId: "0oafn0qce5SENsbif357",
  redirectUri: "https://docupedia.au10tixservicesdev.com/login/callback",
  pkce: true,
  scopes: ['openid', 'profile', 'email'],
  tokenManager: {
    storage: "localStorage",
  },
});

const SecureRoute = ({ element: Element, ...rest }) => {
  const { authState } = useOktaAuth();

  if (!authState || authState.isPending) {
    return <Loading />;
  }
  const idToken = authState?.accessToken?.accessToken;

  return authState.isAuthenticated ? (
    <Element accessToken={idToken} {...rest} />
  ) : (
    <Navigate to="/login" />
  );
};

const SecurityComponent = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Header>
          <CustomHeader data={""} />
        </Header>
        <Routes>
          <Route path="/login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<SecureRoute element={MainPage} />} />
          <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
          <Route
            path="/moderatePanel"
            element={
              <SecureRoute element={AdminComponent} />
            }
          />
          <Route
            path="/moderatePanel/audit"
            element={
              <SecureRoute element={Audit} />
            }
          />
        </Routes>
      </Security>
    </div>
  );
};
export default SecurityComponent;
// import React, { useEffect, useState } from "react";
// import { Route, Routes } from "react-router-dom";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import Loading from "../components/Elements/Loading";
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import MainPage from "../components/MainPage/MainPage";
// import Audit from "../components/AdminConsole/Audit";
// import "./style.css";
// import { Header } from "antd/es/layout/layout";

// const SecurityComponent = () => {
//   const [loading, setLoading] = useState(true);
//   const [user, setUser] = useState(null);
//   const [accessToken, setAccessToken] = useState(null);

//   const fetchAccessToken = async () => {
//     const clientId = process.env.REACT_APP_CLIENT_ID;
//     const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

//     const params = new URLSearchParams();
//     params.append("grant_type", "client_credentials");
//     params.append("scope", "api:read"); // Replace with your desired scope

//     try {
//       const response = await fetch("https://au10tix.okta.com/oauth2/v1/token", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//           Authorization: `Basic ${btoa(`${clientId}:${clientSecret}`)}`, // Base64-encoded credentials
//         },
//         body: params,
//       });
//       console.log("params:", params);
//       if (!response.ok) {
//         throw new Error(`Failed to fetch access token: ${response.status}`);
//       }

//       const data = await response.json();
//       console.log("data !!!", data);

//       console.log("Access Token:", data.access_token);
//       setAccessToken(data.access_token); // Save access token in state
//     } catch (error) {
//       console.error("Error fetching access token:", error.message);
//     }
//   };

//   const fetchUser = async () => {
//     try {
//       const response = await fetch("/.auth/me", {
//         headers: { "Content-Type": "application/json" },
//         credentials: "include",
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch user: ${response.status}`);
//       }

//       const data = await response.json();
//       console.log(JSON.stringify(data));
//       return {
//         user: data?.clientPrincipal || null,
//         accessToken: data?.access_token || null,
//       };
//     } catch (error) {
//       console.error("Error fetching user:", error.message);
//       return null;
//     }
//   };

//   useEffect(() => {
//     const initialize = async () => {
//       try {
//         // Fetch access token using client credentials flow
//         await fetchAccessToken();
//         // Load user information
//         const result = await fetchUser();
//         setUser(result?.user || null);
//       } catch (error) {
//         console.error("Initialization failed:", error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     initialize();
//   }, []);

//   if (loading) {
//     return <Loading />;
//   }

//   return (
//     <div>
//       <Header>
//         <CustomHeader user={user} accessToken={accessToken} />
//       </Header>
//       <Routes>
//         <Route
//           path="/"
//           element={<MainPage user={user} accessToken={accessToken} />}
//         />
//         <Route
//           path="/detail"
//           element={<DetailsDocument user={user} accessToken={accessToken} />}
//         />
//         <Route
//           path="/moderatePanel"
//           element={<AdminComponent user={user} accessToken={accessToken} />}
//         />
//         <Route
//           path="/moderatePanel/audit"
//           element={<Audit user={user} accessToken={accessToken} />}
//         />
//       </Routes>
//     </div>
//   );
// };

// export default SecurityComponent;



// import React from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import MainPage from "../components/MainPage/MainPage";
// import Audit from "../components/AdminConsole/Audit";
// import Loading from "../components/Elements/Loading";
// import "./style.css";
// import { Header } from "antd/es/layout/layout";

// const SecurityComponent = () => {
//     return (
//         <div>
//             <Header>
//                 <CustomHeader data={""} />
//             </Header>
//             <Routes>
//                 <Route path="/" element={<MainPage />} />
//                 <Route path="/detail" element={<DetailsDocument />} />
//                 <Route path="/moderatePanel" element={<AdminComponent />} />
//                 <Route path="/moderatePanel/audit" element={<Audit />} />
//             </Routes>
//         </div>
//     );
// };

// export default SecurityComponent;

// import React, { useState, useEffect } from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import Loading from "../components/Elements/Loading";
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import MainPage from "../components/MainPage/MainPage";
// import Audit from "../components/AdminConsole/Audit";
// import "./style.css";
// import { Header } from "antd/es/layout/layout";

// const fetchUser = async () => {
//     try {
//         const response = await fetch("/.auth/me");
//         if (!response.ok) {
//             console.error(`Error fetching user: ${response.status}`);
//             throw new Error(`Failed to fetch user: ${response.status}`);
//         }

//         const contentType = response.headers.get("content-type");
//         if (!contentType?.includes("application/json")) {
//             console.error("Invalid response format. Expected JSON.");
//             throw new Error("Invalid response format.");
//         }

//         const data = await response.json();
//         return {
//             user: data.clientPrincipal || null,
//             accessToken: data.access_token || null,
//         };
//     } catch (error) {
//         console.error("Authentication error:", error);
//         return null;
//     }
// };

// const SecureRoute = ({ element: Component, roles = [], ...props }) => {
//     const [loading, setLoading] = useState(true);
//     const [user, setUser] = useState(null);
//     const [accessToken, setAccessToken] = useState(null);
  
//     useEffect(() => {
//       const loadUser = async () => {
//         try {
//           const result = await fetchUser();
//           setUser(result?.user || null);
//           setAccessToken(result?.accessToken || null);
//         } catch (err) {
//           console.error("Failed to load user:", err);
//           window.location.href = "/.auth/login/okta";
//         } finally {
//           setLoading(false);
//         }
//       };
//       loadUser();
//     }, []);
  
//     if (loading) {
//       return <Loading />;
//     }
  
//     if (!user) {
//       return null; 
//     }
  
//     if (roles.length && (!user.roles || !roles.some(role => user.roles.includes(role)))) {
//       return <Navigate to="/" />;
//     }
  
//     return <Component user={user} accessToken={accessToken} {...props} />;
//   };
  

// const SecurityComponent = () => {
//     return (
//         <div>
//             <Header>
//                 <CustomHeader data={""} />
//             </Header>
//         <Routes>
//           <Route path="/" element={<SecureRoute element={MainPage} />} />
//           <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
//           <Route path="/moderatePanel" element={<SecureRoute element={AdminComponent} roles={["admin"]} />} />
//           <Route path="/moderatePanel/audit" element={<SecureRoute element={Audit} roles={["admin"]} />} />
//           <Route path="/callback" element={<Loading />} />
//           <Route path="/login" element={<Navigate to="/.auth/login/okta" />} />
//         </Routes>
//         </div>
//     );
// };

// export default SecurityComponent;


// import React from "react";
// import { Route, Routes, useNavigate, Navigate } from "react-router-dom"; // Updated for React Router v6
// import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
// import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import { Login } from "./Login";
// import Loading from '../components/Elements/Loading';
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import { Header } from "antd/es/layout/layout";
// import MainPage from "../components/MainPage/MainPage";
// import "./style.css";
// import Audit from "../components/AdminConsole/Audit";

// const oktaAuth = new OktaAuth({
//   issuer: process.env.REACT_APP_DOCUPEDIA_OKTA_ISSUER,
//   clientId: process.env.REACT_APP_DOCUPEDIA_OKTA_CLIENT_ID,
//   redirectUri: process.env.REACT_APP_DOCUPEDIA_OKTA_REDIRECT_URI,
//   pkce: true,
//   scopes: ['openid', 'profile', 'email', 'groups'],
//   tokenManager: {
//     storage: "localStorage",
//   },
// });

// const SecureRoute = ({ element: Element, ...rest }) => {
//   const { authState } = useOktaAuth();

//   if (!authState || authState.isPending) {
//     return <Loading />;
//   }
//   const idToken = authState?.idToken?.idToken;

//   return authState.isAuthenticated ? (
//     <Element accessToken={idToken} {...rest} />
//   ) : (
//     <Navigate to="/login" />
//   );
// };

// const SecurityComponent = () => {
//   const navigate = useNavigate();

//   const restoreOriginalUri = async (_oktaAuth, originalUri) => {
//     navigate(toRelativeUrl(originalUri || '/', window.location.origin));
//   };

//   return (
//     <div>
//       <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
//         <Header>
//           <CustomHeader data={""} />
//         </Header>
//         <Routes>
//           <Route path="/login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<SecureRoute element={MainPage} />} />
//           <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
//           <Route
//             path="/moderatePanel"
//             element={
//               <SecureRoute element={AdminComponent} />
//             }
//           />
//           <Route
//             path="/moderatePanel/audit"
//             element={
//               <SecureRoute element={Audit} />
//             }
//           />
//         </Routes>
//       </Security>
//     </div>
//   );
// };
// export default SecurityComponent;
// import React, { useState, useEffect } from "react";
// import { Route, Routes, Navigate } from "react-router-dom";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import Loading from "../components/Elements/Loading";
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import MainPage from "../components/MainPage/MainPage";
// import Audit from "../components/AdminConsole/Audit";
// import "./style.css";
// import { Header } from "antd/es/layout/layout";

// const fetchUser = async () => {
//     try {
//         const response = await fetch("/.auth/me");
//         if (!response.ok) {
//             console.error(`Error fetching user: ${response.status}`);
//             throw new Error(`Failed to fetch user: ${response.status}`);
//         }

//         const contentType = response.headers.get("content-type");
//         if (!contentType?.includes("application/json")) {
//             console.error("Invalid response format. Expected JSON.");
//             throw new Error("Invalid response format.");
//         }

//         const data = await response.json();
//         return {
//             user: data.clientPrincipal || null,
//             accessToken: data.access_token || null,
//         };
//     } catch (error) {
//         console.error("Authentication error:", error);
//         return null;
//     }
// };

// const SecureRoute = ({ element: Component, roles = [], ...props }) => {
//     const [loading, setLoading] = useState(true);
//     const [user, setUser] = useState(null);
//     const [accessToken, setAccessToken] = useState(null);
  
//     useEffect(() => {
//       const loadUser = async () => {
//         try {
//           const result = await fetchUser();
//           setUser(result?.user || null);
//           setAccessToken(result?.accessToken || null);
//         } catch (err) {
//           console.error("Failed to load user:", err);
//           window.location.href = "/.auth/login/okta";
//         } finally {
//           setLoading(false);
//         }
//       };
//       loadUser();
//     }, []);
  
//     if (loading) {
//       return <Loading />;
//     }
  
//     if (!user) {
//       return null; 
//     }
  
//     if (roles.length && (!user.roles || !roles.some(role => user.roles.includes(role)))) {
//       return <Navigate to="/" />;
//     }
  
//     return <Component user={user} accessToken={accessToken} {...props} />;
//   };
  

// const SecurityComponent = () => {
//     return (
//         <div>
//             <Header>
//                 <CustomHeader data={""} />
//             </Header>
//         <Routes>
//           <Route path="/" element={<SecureRoute element={MainPage} />} />
//           <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
//           <Route path="/moderatePanel" element={<SecureRoute element={AdminComponent} roles={["admin"]} />} />
//           <Route path="/moderatePanel/audit" element={<SecureRoute element={Audit} roles={["admin"]} />} />
//           <Route path="/callback" element={<Loading />} />
//           <Route path="/login" element={<Navigate to="/.auth/login/okta" />} />
//         </Routes>
//         </div>
//     );
// };

// export default SecurityComponent;


// import React from "react";
// import { Route, Routes, useNavigate, Navigate } from "react-router-dom"; // Updated for React Router v6
// import { Security, LoginCallback, useOktaAuth } from "@okta/okta-react";
// import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
// import DetailsDocument from "../components/Document/DocumentDetails/DetailsDocument";
// import { Login } from "./Login";
// import Loading from '../components/Elements/Loading';
// import AdminComponent from "../components/AdminConsole/AdminComponent";
// import CustomHeader from "../components/Header/CustomHeader";
// import { Header } from "antd/es/layout/layout";
// import MainPage from "../components/MainPage/MainPage";
// import "./style.css";
// import Audit from "../components/AdminConsole/Audit";

// const oktaAuth = new OktaAuth({
//   issuer: process.env.REACT_APP_DOCUPEDIA_OKTA_ISSUER,
//   clientId: process.env.REACT_APP_DOCUPEDIA_OKTA_CLIENT_ID,
//   redirectUri: process.env.REACT_APP_DOCUPEDIA_OKTA_REDIRECT_URI,
//   pkce: true,
//   scopes: ['openid', 'profile', 'email', 'groups'],
//   tokenManager: {
//     storage: "localStorage",
//   },
// });

// const SecureRoute = ({ element: Element, ...rest }) => {
//   const { authState } = useOktaAuth();

//   if (!authState || authState.isPending) {
//     return <Loading />;
//   }
//   const idToken = authState?.idToken?.idToken;

//   return authState.isAuthenticated ? (
//     <Element accessToken={idToken} {...rest} />
//   ) : (
//     <Navigate to="/login" />
//   );
// };

// const SecurityComponent = () => {
//   const navigate = useNavigate();

//   const restoreOriginalUri = async (_oktaAuth, originalUri) => {
//     navigate(toRelativeUrl(originalUri || '/', window.location.origin));
//   };

//   return (
//     <div>
//       <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
//         <Header>
//           <CustomHeader data={""} />
//         </Header>
//         <Routes>
//           <Route path="/login/callback" element={<LoginCallback loadingElement={<Loading />} />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/" element={<SecureRoute element={MainPage} />} />
//           <Route path="/detail" element={<SecureRoute element={DetailsDocument} />} />
//           <Route
//             path="/moderatePanel"
//             element={
//               <SecureRoute element={AdminComponent} />
//             }
//           />
//           <Route
//             path="/moderatePanel/audit"
//             element={
//               <SecureRoute element={Audit} />
//             }
//           />
//         </Routes>
//       </Security>
//     </div>
//   );
// };
// export default SecurityComponent;