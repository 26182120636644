import React from "react";

export const ForgeryList = (props) => {
  let count = 1;

  return (
    <li key={count++}>
      {props.item.type} :: {props.item.subtype}
    </li>
  );
};

export default ForgeryList;
