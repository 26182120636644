import React from "react";

export const DataExtractionList = (props) => {
  let count = 1;

  return (
    <li key={count++}>
      {props.item.fieldSource} :: {props.item.name}
    </li>
  );
};

export default DataExtractionList;
