import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Tabs } from "antd";
import ForgeryList from "../Forgery/ForgeryList";
import DataExtractionList from "../DataExtraction/DataExtractionList";
import "./DocumentData.css";

const { TabPane } = Tabs;

export default function DocumentData({ data = [], flagForgery = false }) {
  const [documentData, setDocumentData] = useState([]);
  const [frontData, setFrontData] = useState([]);
  const [backData, setBackData] = useState([]);
  const [defaultKey, setDefaultKey] = useState("1");

  // Process data based on fieldSource or type
  const processData = useCallback(
    (data) => {
      const docData = [];
      const frontD = [];
      const backD = [];

      data.forEach((item) => {
        const isDataExtraction = item.DataField != null;
        const fieldData = isDataExtraction ? item.DataField : item;
        const { name, source } = fieldData;

        // Filter out unnecessary fields
        if (name !== "LanguageCode" && name !== "LocalLanguage" && source !== "Undefined") {
          switch (source) {
            case "Document":
              docData.push(fieldData);
              break;
            case "Front":
              frontD.push(fieldData);
              break;
            case "Back":
              backD.push(fieldData);
              break;
            default:
              break;
          }
        }
      });

      const sortByField = (a, b, field) => a[field]?.localeCompare(b[field]);
      return {
        docData: docData.sort((a, b) => sortByField(a, b, "fieldSource") || sortByField(a, b, "type")),
        frontD: frontD.sort((a, b) => sortByField(a, b, "fieldSource") || sortByField(a, b, "type")),
        backD: backD.sort((a, b) => sortByField(a, b, "fieldSource") || sortByField(a, b, "type")),
      };
    },
    []
  );

  // UseEffect to handle data processing and key setting
  useEffect(() => {
    const { docData, frontD, backD } = processData(data);
    setDocumentData(docData);
    setFrontData(frontD);
    setBackData(backD);

    // Set default tab based on data availability
    if (docData.length) setDefaultKey("1");
    else if (frontD.length) setDefaultKey("2");
    else if (backD.length) setDefaultKey("3");
  }, [data, processData]);

  // Memoized tab rendering to avoid unnecessary re-renders
  const renderTabPane = useMemo(
    () => (dataArray, key, title) => {
      if (dataArray.length === 0) return null;

      return (
        <TabPane tab={title} key={key}>
          <ul>
            {dataArray.map((item) =>
              flagForgery ? (
                <ForgeryList key={item.fieldSource || item.type} item={item} />
              ) : (
                <DataExtractionList key={item.fieldSource || item.type} item={item} />
              )
            )}
          </ul>
        </TabPane>
      );
    },
    [flagForgery]
  );

  return (
    <div className="document-data-container">
      <Tabs defaultActiveKey={defaultKey}>
        {renderTabPane(documentData, "1", "Document")}
        {renderTabPane(frontData, "2", "Front side")}
        {renderTabPane(backData, "3", "Back side")}
      </Tabs>
    </div>
  );
}
