import axios from "axios";
import { MainUrl } from "./ServiceEndpoints";

export const getDocuments = async (page, pageSize, filters = {}, token) => {
    let queryString = `page=${page}&pageSize=${pageSize}`;

    // Helper function to append array filters to the query string
    const appendArrayParams = (key, values) => {
        if (Array.isArray(values) && values.length) {
            queryString += `&${key}=${values.join(',')}`;
        }
    };

    // Append filter parameters if they exist
    appendArrayParams('countryCode', filters.countryCode);
    appendArrayParams('documentType', filters.documentType);
    appendArrayParams('state', filters.state);
    appendArrayParams('documentVersion', filters.documentVersion);
    appendArrayParams('yearOfIssue', filters.yearOfIssue);
    appendArrayParams('alias', filters.alias);
    appendArrayParams('status', filters.status);

    // Append freeSearch if it's not empty
    if (filters.freeSearch) {
        queryString += `&freeSearch=${encodeURIComponent(filters.freeSearch)}`;
    }

    // Encode the entire query string
    const encodedQuery = encodeURIComponent(queryString);

    // Pass the encoded query string as the 'query' parameter
    try {
        const response = await axios.get(`${MainUrl.GetDocuments}?query=${encodedQuery}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching documents:", error);
        throw error;
    }
};


export const getOptions = async (key, filters = {}, nestedObj, token) => {
    try {
        let str = JSON.stringify(filters);
        const url = `${MainUrl.GetOptions}?key=${key}&nestedObj=${nestedObj}&currentFilters=${encodeURIComponent(str)}`;

        const response = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data || [];
    } catch (error) {
        console.error("Error fetching options:", error);
        throw error;
    }
};



// export const getDocuments = async (page, pageSize, filters = {}, token) => {
//     const params = new URLSearchParams();

//     // Add pagination parameters
//     params.append('page', page);
//     params.append('pageSize', pageSize);

//     // Add array parameters (CountryCode, DocumentType, State) if they are not empty
//     if (filters.countryCode?.length) {
//         filters.countryCode.forEach(code => params.append('CountryCode', code));
//     }
//     if (filters.documentType?.length) {
//         filters.documentType.forEach(type => params.append('DocumentType', type));
//     }
//     if (filters.state?.length) {
//         filters.state.forEach(state => params.append('State', state));
//     }
//     if (filters.version?.length) {
//         filters.version.forEach(version => params.append('DocumentVersion', version));
//     }
//     if (filters.yearOfIssue?.length) {
//         filters.yearOfIssue?.forEach(yearOfIssue => params.append('YearOfIssue', yearOfIssue));
//     }
//     if (filters.alias?.length) {
//         filters.alias.forEach(alias => params.append('Alias', alias));
//     }
//     if (filters.status?.length) {
//         filters.status.forEach(status => params.append('StatusCode', status));
//     }
//     if (filters.freeSearch != '') {
//         params.append('freeSearch', filters.freeSearch )
//     }
//     try {
//         const response = await axios.get(MainUrl.GetDocuments, { params }, {
//             headers: {
//                 Authorization: `Bearer ${token}`
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.error("Error fetching documents:", error);
//         throw error;
//     }
// };
