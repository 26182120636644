import React from "react";

const BackButton = () => {

  // Retrieve the URL from localStorage or use the homepage as a fallback
  const backUrl = localStorage.getItem("backUrl") || "/"; 

  const handleBackClick = () => {
    window.location.href = backUrl;
  };

  return (
    <div style={{ marginTop: "0.7%", marginLeft: "1%", marginRight: "1%" }}>
      <div 
        onClick={handleBackClick} 
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <svg
          width="1em"
          height="1em"
          viewBox="2 2 10 10"
          className="bi bi-arrow-left"
          fill="#001529"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M5.854 4.646a.5.5 0 0 1 0 .708L3.207 8l2.647 2.646a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 0 1 .708 0z"
          />
          <path
            fillRule="evenodd"
            d="M2.5 8a.5.5 0 0 1 .5-.5h10.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
          />
        </svg>
        <span
          style={{
            color: "#001529",
            fontSize: 15,
            fontWeight: 800,
            marginLeft: 10,
          }}
        >
          BACK
        </span>
      </div>
    </div>
  );
};

export default BackButton;
