import React from "react";
import { List, Label } from "semantic-ui-react";
import "./Profile.css";

function Profile({ document }) {
  const attributes = document.attributes;

  const labelsForFirstColumn = {
    "Country code:": document.countryCode,
    "Document type:": document.documentType,
    "Document version:": document.documentVersion,
    "Page:": document.attributes?.Page,
    "Supported from: ": attributes?.supportedFrom,
    "Alias:": attributes?.alias,
    "Sides:": document.status,
    "SupportStatus": document.SupportStatus,
    "RealId": attributes?.RealId,
    "Signature status:": attributes?.SignatureStatus,
  };

  const labelsForSecondColumn = {
    "Issue year:": attributes?.yearOfIssue,
    "Language:": attributes?.LocalLanguage,
    "Size:": attributes?.size,
    "Has MRZ:": attributes?.mrz,
    "Has Chip ": attributes?.SignatureStatus,
    "Barcode status:": attributes?.BarcodeStatus,
    "Barcode type:": attributes?.BarcodeType,
    "Digital Candidate ": attributes?.DigitalCandidate,
    "Second Side Significant ": attributes?.SecondSideSignificant,
  };

  const prepareList = (key, value) => (
    <List.Item key={key} className="profile-item">
      <Label className="profile-label" horizontal>
        {key}
      </Label>
      <span className="profile-value">{value || "Unknown"}</span>
    </List.Item>
  );

  return (
    <div className="profile-container">
      <div className="profile-column">
        <List>
          {Object.entries(labelsForFirstColumn).map(([key, value]) =>
            prepareList(key, value)
          )}
        </List>
      </div>

      <div className="profile-column">
        <List>
          {Object.entries(labelsForSecondColumn).map(([key, value]) =>
            prepareList(key, value)
          )}
        </List>
      </div>
    </div>
  );
}

export default Profile;
