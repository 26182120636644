import React from "react";
import { Card , Icon, Image } from "semantic-ui-react";
import Flag from "react-world-flags";
import { Link } from "react-router-dom";
import "./CustomCard.css";
import { getCountryName } from "../../utils/Utils";

export const CustomCard = ({ documents }) => {

  const handleClick = () => {
    const currentUrl = window.location.href;
    localStorage.setItem("backUrl", currentUrl);
  };

  return (
    <div className="card-container" id="animation">
      {documents?.length ? (
        <Card.Group itemsPerRow={5} stackable doubling>
          {documents.map((document) => (

            <Card
              onClick={() => handleClick()}
              key={document.id}
              className="fluid"
            >
              <Link to={`/detail?id=${document.id}`} target="_blank">
               
                <Card.Content>
                  <Flag
                    className="flag"
                    code={document.country !== "D" ? document.country : "DEU"}
                    height="30"
                    width="45"
                  />
                  <Card.Header className="title">
                    {prepareTitleDoc(document)} 
                  </Card.Header>
                  {/* <Card.Header>
                    Supported from version{" "}
                    {document.supported !== "Undefined" ? document.supported : null}
                  </Card.Header> */}
                  
                  {document.supported === "" || document.supported !== "Undefined" || document.supported !== null 
                  ? null : <Card.Meta>
                    <Icon name="calendar" />
                    <span>Published date {document.attributes?.yearOfIssue}</span>
                  </Card.Meta>}

                  {document.attributes?.yearOfIssue === "" ? null : 
                  <Card.Meta>
                    <Icon name="calendar" />
                    <span>Published date {document.attributes?.yearOfIssue}</span>
                  </Card.Meta>}
                  {document.attributes?.LocalLanguage === "" ? null : 
                  <Card.Meta>
                    <Icon name="language" />
                    <span>Language {document.attributes?.LocalLanguage }</span>
                  </Card.Meta>}
                </Card.Content>
                {document.images && document.images.length > 0 && document.images.some((image) => image.imageStatus === "Approved") && (
                  <div className="image-container" style={{marginTop: "6px"}}>
                    <div className="thumbnail-container">
                      {document.images.map((image, index) => ( 
                        image.imageStatus === "Approved" 
                        ? 
                        <Image
                          size="small"
                          wrapped
                          ui={false}
                          key={index}
                          src={image.uri}
                          alt={`Thumbnail ${index + 1} for ${document.id}`}
                          loading="lazy" // Lazy load images
                        /> 
                        : null
                      ))}
                    </div>
                  </div>
                )}
              </Link>
            </Card>
          ))}
        </Card.Group>
      ) : null}
    </div>
  );
};

const prepareTitleDoc = (document) => {
  const country = document.country === "D" ? "DEU" : document.country
  const elements = [
    getCountryName(country) + (" (" + country + ")"),
    document.type,
    document.documentVersion,
    document.state
  ];

  return elements?.filter(element => typeof element === "string" && element !== "")
    .join(" | ");
};

export default CustomCard;
