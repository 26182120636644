import React, { useState, useEffect } from "react";
import "./Document.css";
import DocumentData from "./DocumentData/DocumentData";
import CarouselImages from "../Image/CarouselImages";
import Profile from "../Document/Profile/Profile";
import { Grid } from "semantic-ui-react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loading from "../Elements/Loading";
import { getCountryName } from "../../utils/Utils";

export default function Document({ document, title }) {
  const [backImages, setBackImages] = useState([]);
  const [frontImages, setFrontImages] = useState([]);
  const [docTitle, setDocTitle] = useState("");

  useEffect(() => {
    if (document) {
      // Front and back arrays with titles
      const fronts = document.images?.filter((img) => img.documentSide === "Front")
        .map((img) => ({ uri: img.uri, title: img.documentSide }));

      const backs = document.images?.filter((img) => img.documentSide === "Back")
        .map((img) => ({ uri: img.uri, title: img.documentSide }));

      setFrontImages(fronts);
      setBackImages(backs);
      setDocTitle(prepareTitleDoc(document));
    }
  }, [document]);

  if (!document) {
    return <div><Loading/></div>;
  }

  return (
    <div>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={5}>
            {frontImages?.length > 0 ? (
              <CarouselImages
                header={`${frontImages[0].title} side`}
                image={frontImages?.map((img) => img.uri)}
              />
            ) : (
              document?.status === "SinglePage" || document?.status === "MultiPage" ? (
                <CarouselImages header={"First side"} image={frontImages?.map((img) => img.uri)} />
              ) : null
            )}
          </Grid.Column>
          <Grid.Column width={11} id="animation">
            <div className="profile-header">
              <h5 className="profile-title">Profile</h5>
              <h4 className="doc-title">{docTitle}</h4>
            </div>
            <Profile document={document} />
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={5} style={{
            height: 'auto',
            backgroundColor: 'white'
          }}>
            {backImages?.length > 0 ? (
              <CarouselImages
                header={`${backImages[0].title} side`}
                image={backImages?.map((img) => img.uri)}
              />
            ) : document.status === "MultiPage" ? (
              <CarouselImages header={"Second side"} image={backImages?.map((img) => img.uri)} />
            ) : null}
          </Grid.Column>
          <Grid.Column width={5} id="animation" style={{
            height: 'auto',
            backgroundColor: 'white'
          }}>
            <h5>Data extraction</h5>
            <DocumentData
              data={document.dataFields}
              header={"Data extraction"}
              flag={"dataExtraction"}
              flagForgery={false}
            />
          </Grid.Column>
          <Grid.Column width={6} id="animation" style={{
            height: 'auto',
            backgroundColor: 'white', marginBottom: "1%"
          }}>
            <h5>Forgery tests</h5>
            <DocumentData
              data={document.forgeryTests}
              header={"Forgeries"}
              flag={"forgery"}
              flagForgery={true}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}

function prepareTitleDoc(document) {
  const elements = [
    getCountryName(document.countryCode),
    document.documentType,
    document.documentVersion,
    document.state,
  ];

  return elements?.filter((element) => typeof element === "string" && element !== "")
    .join(" | ");
}
