import React, { useEffect, useState } from "react";
import { MainUrl } from "../../../services/ServiceEndpoints";
import Document from "../Document";
import axios from "axios";
import "react-awesome-slider/dist/styles.css";
import BackButton from "../../Elements/Back";

const DetailsDocument = ({ accessToken }) => {
  const [document, setDocument] = useState(null);

  useEffect(() => {
    const fetchDocumentData = async () => {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            const id = urlParams.get('id');
            if (!id) {
                console.error('Document ID is missing from the URL');
                return;
            }

            const documentResponse = await axios.get(
                `${MainUrl.GetDocumentDataById}?id=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );

            const urisResponse = await axios.get(
                `${MainUrl.GetSasUrlByDocumentId}?id=${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                }
            );

            const documentData = documentResponse.data;
            const uris = urisResponse.data;

            if (documentData?.images?.length > 0 && uris) {
                documentData.images.forEach((image) => {
                    if (image.documentSide && uris[image.documentSide]) {
                        image.uri = uris[image.documentSide];
                    }
                });
            }

            setDocument(documentData);
        } catch (error) {
            console.error('Error fetching document data:', error.message || error);
        }
    };

    fetchDocumentData();
}, [accessToken]);


  return (
    <div>
      <div style={{ marginTop: "0.7%", marginLeft: "1%", marginRight: "1%" }}>
        <BackButton />
      </div>
      {document && <Document document={document} />}
    </div>
  );
};

export default DetailsDocument;
