import React, { useState, useEffect } from 'react';
import { getDocuments, getOptions } from '../../services/documentService.jsx';
import { Select, Layout, Pagination, Alert, Input, Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomCard from '../CustomCard/CustomCard.jsx';
import queryString from 'query-string';
import { createErrorElement, getCountryName, prepareDocStatus } from '../../utils/Utils.jsx';
import axios from 'axios';
import { MainUrl } from '../../services/ServiceEndpoints.jsx';
import { SearchOutlined } from '@ant-design/icons';
import { useOktaAuth } from "@okta/okta-react";
import { userGroups } from "../../utils/Commons.jsx";

const { Sider, Content } = Layout;

const MainPage = ({ accessToken }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { oktaAuth } = useOktaAuth();

    const [groups, setGroups] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [options, setOptions] = useState({});
    const [filters, setFilters] = useState({});
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalDocuments, setTotalDocuments] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);  // Error state
    const [searchValue, setSearchValue] = useState(filters.freeSearch);

    const handleOpenPage = () => {
        window.open("/moderatePanel", "_blank");
    };

    const handleOpenPageAudit = () => {
        window.open("/moderatePanel/audit", "_blank");
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                setError(null);
                const parsedQuery = queryString.parse(location.search);
                const initialFilters = getFiltersFromQuery(parsedQuery);
                setFilters(initialFilters);

                // Initialize page and page size
                setPage(parsedQuery.page ? parseInt(parsedQuery.page, 10) : 1);
                setPageSize(parsedQuery.pageSize ? parseInt(parsedQuery.pageSize, 10) : 20);

                await fetchOptionsForFilters(initialFilters, options, true);
                await loadDocuments(initialFilters, parsedQuery.page || 1, parsedQuery.pageSize || 20, parsedQuery);
            } catch (error) {
                setError('Failed to fetch initial data. Please try again.' + (error.message || 'Unknown error'));
            }
        };

        fetchInitialData();
    }, [location.search]);

    useEffect(() => {
        const fetchUserGroupsFromToken = async () => {
            try {
                // Retrieve the ID token using Okta SDK
                const idToken = await oktaAuth.tokenManager.get('accessToken');
                if (!idToken) {
                    console.error("ID Token is missing");
                    return;
                }
                const userGroups = idToken?.claims?.groups || [];
                const userName = idToken?.claims?.firstName + " " + idToken?.claims?.lastName || "Unknown";

                // Update state and localStorage
                setGroups(userGroups);
                localStorage.setItem("groups", JSON.stringify(userGroups));
                localStorage.setItem("userName", userName);
            } catch (error) {
                console.error("Failed to retrieve or parse ID token:", error);
            }
        };

        fetchUserGroupsFromToken();
    }, []);


    const formatOptions = (optionList) =>
        optionList?.filter(option => option !== '' && option != null)
            .map(option => ({ value: option, label: option }));

    const getFiltersFromQuery = (queryParams) => ({
        countryCode: queryParams.countryCode ? queryParams.countryCode.split(',') : [],
        documentType: queryParams.documentType ? queryParams.documentType.split(',') : [],
        state: queryParams.state ? queryParams.state.split(',') : [],
        documentVersion: queryParams.documentVersion ? queryParams.documentVersion.split(',') : [],
        yearOfIssue: queryParams.yearOfIssue ? queryParams.yearOfIssue.split(',') : [],
        alias: queryParams.alias ? queryParams.alias.split(',') : [],
        status: queryParams.status ? queryParams.status.split(',') : [],
        freeSearch: queryParams.freeSearch ? queryParams.freeSearch.split(',') : [],
    });

    const buildQueryString = (updatedFilters, pageNum, size) => queryString.stringify({
        ...updatedFilters,
        page: pageNum,
        pageSize: size,
    }, { arrayFormat: 'comma' });

    const handleFilterChange = async (key, value) => {
        const updatedFilters = { ...filters, [key]: value };
        setFilters(updatedFilters);

        const newQueryString = buildQueryString(updatedFilters, 1, pageSize);
        navigate({ pathname: location.pathname, search: `?${newQueryString}` });

        await loadDocuments(updatedFilters, 1, pageSize);
        if (key === "freeSearch") {
            return;
        }
        await fetchOptionsForFilters(updatedFilters, key, false, options);
    };

    const fetchOptionsForFiltersFirstInit = async (updatedFilters) => {
        try {
            setError(null);
            const [countries, documentTypes, states, documentVersions, yearsOfIssue, aliases, statusCodes] = await Promise.all([
                getOptions('countryCode', updatedFilters, "no", accessToken),
                getOptions('documentType', updatedFilters, "no", accessToken),
                getOptions('state', updatedFilters, "no", accessToken),
                getOptions('documentVersion', updatedFilters, "no", accessToken),
                getOptions('yearOfIssue', updatedFilters, "attributes", accessToken),
                getOptions('alias', updatedFilters, "attributes", accessToken),
                getOptions('status', updatedFilters, "no", accessToken),
            ]);

            const documentVerRefacted = documentVersions?.map(v =>
                typeof v === 'string' ? v.replace(/,/g, "_") : v
            );

            setOptions({
                countries: countries.map(option => ({
                    value: option, // Use ISO code as the value
                    label: `${option} (${getCountryName(option)})` // Display both ISO and full name
                })),
                documentTypes: formatOptions(documentTypes),
                states: formatOptions(states),
                documentVersions: formatOptions(documentVerRefacted),
                yearsOfIssue: formatOptions(yearsOfIssue),
                aliases: formatOptions(aliases),
                status: formatOptions(statusCodes),
            });
        } catch (error) {
            setError(createErrorElement(error, "fetchOptionsForFilters"));
        }
    };

    const fetchOptionsForFilters = async (updatedFilters, key, firstInitialization, currentOptions) => {
        try {
            if (firstInitialization) {
                fetchOptionsForFiltersFirstInit(updatedFilters);
            } else {
                const promises = [];

                if (key !== 'countryCode' || updatedFilters?.countryCode?.length === 0) {
                    promises.push(getOptions('countryCode', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.countries);
                }

                if (key !== 'documentType' || updatedFilters?.documentType?.length === 0) {
                    promises.push(getOptions('documentType', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.documentTypes);
                }

                if (key !== 'state' || updatedFilters?.state?.length === 0) {
                    promises.push(getOptions('state', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.states);
                }

                if (key !== 'documentVersion' || updatedFilters?.documentVersion?.length === 0) {
                    promises.push(getOptions('documentVersion', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.documentVersions);
                }

                if (key !== 'yearOfIssue' || updatedFilters?.yearOfIssue?.length === 0) {
                    promises.push(getOptions('yearOfIssue', updatedFilters, "attributes", accessToken));
                } else {
                    promises.push(currentOptions.yearsOfIssue);
                }

                if (key !== 'alias' || updatedFilters?.alias?.length === 0) {
                    promises.push(getOptions('alias', updatedFilters, "attributes", accessToken));
                } else {
                    promises.push(currentOptions.aliases);
                }

                if (key !== 'status' || updatedFilters?.status?.length == 0) {
                    promises.push(getOptions('status', updatedFilters, "no", accessToken));
                } else {
                    promises.push(currentOptions.status);
                }

                const [countries, documentTypes, states, documentVersions, yearsOfIssue, aliases, statusCodes] = await Promise.all(promises);

                const isFormatted = (options) => Array.isArray(options)
                    && options?.length > 0
                    && options[0] !== null
                    && typeof options[0] === 'object'
                    && options[0].hasOwnProperty('label')
                    && options[0].hasOwnProperty('value');

                const documentVerRefacted = documentVersions?.map(v =>
                    typeof v === 'string' ? v.replace(/,/g, "_") : v
                );
                // Set the options after formatting if needed
                setOptions({
                    countries: !isFormatted(countries) ? countries.map(option => ({
                        value: option,
                        label: `${option} (${getCountryName(option)})`
                    })) : currentOptions?.countries,
                    documentTypes: !isFormatted(documentTypes) ? formatOptions(documentTypes) : currentOptions?.documentTypes,
                    states: !isFormatted(states) ? formatOptions(states) : currentOptions?.states,
                    documentVersions: !isFormatted(documentVerRefacted) ? formatOptions(documentVerRefacted) : currentOptions?.documentVersions,  // Consistent naming for 'documentVersions'
                    yearsOfIssue: !isFormatted(yearsOfIssue) ? formatOptions(yearsOfIssue) : currentOptions?.yearsOfIssue,
                    aliases: !isFormatted(aliases) ? formatOptions(aliases) : currentOptions?.aliases,
                    status: !isFormatted(statusCodes) ? formatOptions(statusCodes) : currentOptions?.status,
                });
            }
            setError(null);
        } catch (error) {
            setError(createErrorElement(error, "fetchOptionsForFilters"));
        }
    };

    const loadDocuments = async (filterData = filters, pageNum = page, size = pageSize, queryStringified) => {
        setIsLoading(true);
        try {
            setError(null);
            const response = await getDocuments(pageNum, size, filterData, accessToken, queryStringified);
            setDocuments(await formatDocuments(response?.Data));
            setTotalDocuments(response.TotalCount);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            setError(createErrorElement(error, "loadDocuments"));
        } finally {
            setIsLoading(false);
        }
    };
    const clearFilters = async () => {
        setFilters({});

        const params = new URLSearchParams();

        params.set('page', '1');
        params.set('pageSize', pageSize.toString());

        navigate(`${location.pathname}?${params.toString()}`);

        setSearchValue(null);
    };

    const formatDocuments = async (data) => {
        return Promise.all(data?.map(async (doc) => {
            const res = JSON.parse(doc);
            let uris = await axios(MainUrl.GetSasUrlByDocumentId + "?id=" + res.id, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            });
            Object.keys(uris.data)?.forEach(key => {
                // Check if res.images exists and iterate over it
                if (res.images?.length > 0) {
                    res.images.forEach(x => {
                        if (x.documentSide == key) {
                            x.uri = uris.data[key]; // Update the URI if documentSide matches key
                        }
                    });
                }
            })

            return {
                country: res.countryCode,
                type: res.documentType,
                documentVersion: res.documentVersion,
                state: res.state,
                supported: res.attributes?.supportedFrom,
                supportedPages: prepareDocStatus(res.SupportStatus),
                id: res.id,
                attributes: res.attributes,
                images: res.images,
                yearOfIssue: res.attributes?.yearOfIssue === '' ? null : res.attributes?.yearOfIssue,
            };
        }));
    };

    const handlePageChange = async (newPage, newPageSize) => {
        setPage(newPage);
        setPageSize(newPageSize);

        const queryStringified = buildQueryString(filters, newPage, newPageSize);
        navigate({ pathname: location.pathname, search: `?${queryStringified}` });

        await loadDocuments(filters, newPage, newPageSize, queryStringified);
    };

    const handleFreeSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const onPageSizeChange = (current, newPageSize) => {
        setPageSize(newPageSize);
        setPage(1);

        const queryStringified = buildQueryString(filters, 1, newPageSize);
        navigate({ pathname: location.pathname, search: `?${queryStringified}` });

        loadDocuments(filters, 1, newPageSize, queryStringified);
    };

    const isModerator = groups?.some((group) =>
        group.includes(userGroups.Moderator)
    );

    return (
        <Layout>
            {isLoading && <div className="loading">Loading&#8230;</div>}
            <Sider width="320">
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />
                <FilterSelect
                    options={options.countries}
                    placeholder="Select country"
                    value={filters.countryCode}
                    onChange={(val) => handleFilterChange('countryCode', val)}
                />
                <FilterSelect
                    options={options.documentTypes}
                    placeholder="Select document type"
                    value={filters.documentType}
                    onChange={(val) => handleFilterChange('documentType', val)}
                />
                <FilterSelect
                    options={options.states}
                    placeholder="Select state"
                    value={filters.state}
                    onChange={(val) => handleFilterChange('state', val)}
                />
                <FilterSelect
                    options={options.documentVersions}
                    placeholder="Select version"
                    value={filters.documentVersion}
                    onChange={(val) => handleFilterChange('documentVersion', val)}
                />
                <FilterSelect
                    options={options.yearsOfIssue}
                    placeholder="Select year of issue"
                    value={filters.yearOfIssue}
                    onChange={(val) => handleFilterChange('yearOfIssue', val)}
                />
                <FilterSelect
                    options={options.aliases}
                    placeholder="Select alias"
                    value={filters.alias}
                    onChange={(val) => handleFilterChange('alias', val)}
                />
                <FilterSelect
                    options={options.status}
                    placeholder="Select status code"
                    value={filters.status}
                    onChange={(val) => handleFilterChange('status', val)}
                />
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />
                <div style={{ margin: '16px', display: 'flex', alignItems: 'center' }}>
                    <Input
                        value={searchValue != null ? searchValue : filters?.freeSearch}
                        onChange={handleFreeSearchChange}
                        placeholder="Free search..."
                        style={{
                            padding: '10px',
                            height: '35px',
                            flexGrow: 1,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }}
                    />
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={(e) => handleFilterChange('freeSearch', [searchValue])}
                        style={{
                            height: '35px',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '45px',
                        }}
                    />
                </div>
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />

                <div style={{ textAlign: 'left', margin: 16 }}>
                    <a href="#" onClick={clearFilters}>Clear all filters</a>
                </div>
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />

                <div style={{ textAlign: 'left', margin: 16, position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '8px', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>
                    <p
                        style={{
                            fontSize: '14px',
                            borderRadius: '8px',
                            marginLeft: '2px'
                        }}
                    >
                        Found documents:  {totalDocuments}
                    </p>
                </div>
                <hr style={{ width: "auto", border: "none", borderTop: "0.5px dotted #000", margin: "18px" }} />
                {isModerator ? (
                    <div style={{
                        textAlign: 'left',
                        margin: 16,
                        bottom: 0,
                        width: "auto",
                        left: 0,
                        padding: '8px',
                        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)'
                    }}>
                        <Button type="primary" style={{ fontWeight: 700, width: "100%", }} key="moderator" onClick={handleOpenPage}>
                            Moderator Console
                        </Button>
                        <Button type="primary"
                            style={{ fontWeight: 700, width: "100%", marginTop: "10px" }} key="moderator" onClick={handleOpenPageAudit}>
                            Data Transfer Audit
                        </Button>
                    </div>
                ) : null}

            </Sider>
            <Content style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 64px)' }}>
                {error && <div> <Alert message={error} type="error" showIcon style={{ margin: "15px" }} /></div>}

                <div style={{ flex: 1, overflowY: 'auto' }}>
                    <CustomCard documents={documents} />
                </div>

                <div style={{ position: 'sticky', bottom: 0, backgroundColor: 'white', padding: '16px', boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)' }}>

                    <Pagination
                        current={page}
                        pageSize={pageSize}
                        pageSizeOptions={['10', '20', '50']}
                        total={totalDocuments}
                        onChange={handlePageChange}
                        onShowSizeChange={onPageSizeChange}
                        showSizeChanger
                    />
                </div>
            </Content>
        </Layout>
    );
};

const FilterSelect = ({ options, placeholder, value, onChange }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const handleSelectChange = (selectedValues) => {
        onChange(selectedValues);
        setDropdownVisible(false);
    };

    const handleDropdownVisibleChange = (open) => {
        setDropdownVisible(open);
    };

    return (
        <div style={{ margin: 16 }}>
            <Select
                mode="multiple"
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder={placeholder}
                optionFilterProp="label"
                value={value}
                options={options}
                onChange={handleSelectChange}
                open={dropdownVisible}
                onDropdownVisibleChange={handleDropdownVisibleChange}
            />
        </div>
    );
};


export default MainPage;