import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Loading = ({textAlign}) => {
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div style={{ textAlign: textAlign == null ? "center" : textAlign, padding: '20px' }}>
      <h3 style={{ marginTop: '10px' }}>Loading, please wait...</h3>
      <Spin indicator={loadingIcon} />
    </div>
  );
};

export default Loading;
