import React, { useEffect, useState } from "react";
import Au10tixLogo from "../../assets/icons/Au10tixLogo.svg";
import { useOktaAuth } from "@okta/okta-react";
import { Button, Dropdown, Menu } from "antd";
import { CSVLink } from "react-csv";
import "./CustomHeader.css";
import { MainUrl } from "../../services/ServiceEndpoints.jsx";
import axios from "axios";

export default function CustomHeader({accessToken}) {
  const { oktaAuth } = useOktaAuth();
  const [data, setData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    renderButtons();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
        try {
          setError(null);
          let data = await axios(MainUrl.GetDocumentsDataForCsv, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
        setData(data.data);
        } catch (error) {
            setError('Failed to fetch initial data. Please try again.' + (error.message || 'Unknown error'));
        }
    };
    if (!window.location.href.includes("moderatePanel")) {
      fetchData();
    } 
}, [accessToken]);

  const logout = async () => {
    await oktaAuth.signOut();
  };

  const renderExportOptions = () => (
    <Menu>
      <Menu.Item>
        <CSVLink data={data || []} filename="Supported_docs.csv">
          All supported documents (.csv)
        </CSVLink>
      </Menu.Item>
      {/* <Menu.Item onClick={() => exportPDF(data)}>
        All documents (.pdf)
      </Menu.Item> */}
    </Menu>
  );

  const renderButtons = () => {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "10px",
        }}
      >
        <Dropdown style={{ fontWeight: 700 }} overlay={renderExportOptions()} trigger={['click']}>
          <Button style={{ fontWeight: 700 }} >Export</Button>
        </Dropdown>
        <Button style={{ fontWeight: 700 }} onClick={logout}>
          Log out
        </Button>
      </div>
    );
  }

  return (
    <div className="flex-header-container">
      <div className="header-wrapper">
        <img width="auto" height="25px" alt="logo" src={Au10tixLogo} />
        <h1 className="page-header">DOCU</h1>
      </div>

      {renderButtons()}

    </div>
  );
}

// import React, { useEffect, useState } from "react";
// import Au10tixLogo from "../../assets/icons/Au10tixLogo.svg";
// import { Button, Dropdown, Menu } from "antd";
// import { CSVLink } from "react-csv";
// import "./CustomHeader.css";
// import { MainUrl } from "../../services/ServiceEndpoints.jsx";
// import axios from "axios";

// export default function CustomHeader({ accessToken }) {
//   const [data, setData] = useState([]);
//   const [error, setError] = useState(null);

//   // Fetch data for export
//   useEffect(() => {
//     const fetchData = async () => {
//       if (!accessToken) {
//         console.warn("Access token is missing");
//         return;
//       }

//       try {
//         const response = await axios.get(MainUrl.GetDocumentsDataForCsv, {
//           headers: {
//             Authorization: `Bearer ${accessToken}`,
//           },
//         });
//         setData(response.data);
//       } catch (err) {
//         const message = err.response?.data?.message || err.message || "Unknown error";
//         setError(`Failed to fetch data: ${message}`);
//         console.error("Error fetching data:", err);
//       }
//     };
//     if (!window.location.href.includes("moderatePanel")) {
//       fetchData();
//     }
//   }, [accessToken]);

//   const logout = () => {
//     try {
//       window.location.href = "/.auth/logout?post_logout_redirect_uri=/";
//     } catch (error) {
//       console.error("Logout failed:");
//     }
//   };

//   const renderExportOptions = () => (
//     <Menu>
//       <Menu.Item key="export-csv">
//         <CSVLink data={data} filename="Supported_docs.csv">
//           All supported documents (.csv)
//         </CSVLink>
//       </Menu.Item>
//     </Menu>
//   );

//   return (
//     <div className="flex-header-container">
//       <div className="header-wrapper">
//         <img width="auto" height="25px" alt="logo" src={Au10tixLogo} />
//         <h1 className="page-header">DOCU</h1>
//       </div>

//       <div className="header-buttons">
//         <Dropdown  overlay={renderExportOptions()} trigger={["click"]}>
//           <Button style={{ fontWeight: "bold", marginRight: "10px", marginBottom: "20px" }}>Export</Button>
//         </Dropdown>
//         <Button style={{ fontWeight: "bold" }} onClick={logout}>
//           Log out
//         </Button>
//       </div>

//       {error && <div className="error-message">{error}</div>}
//     </div>
//   );
// }
