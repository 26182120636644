import React, { useEffect, useState } from 'react';
import ModeratePanel from './ModeratePanel';
import { userGroups } from '../../utils/Commons';

const AdminComponent = ({ accessToken }) => {
    const [isModerator, setIsModerator] = useState();
    // const isContributor = groups.some(group => group.includes(userGroups.Contributor));
    useEffect(() => {
        const groups = localStorage.getItem("groups")?.split(',') || [];

        const isModerator = groups.some(group => group.includes(userGroups.Moderator));
        setIsModerator(isModerator);
    }, []);

    if (isModerator) {
        return <ModeratePanel accessToken={accessToken} />;
    }

    return <div>No Access</div>;
};

export default AdminComponent;

// import React, { useEffect, useState } from "react";
// import ModeratePanel from "./ModeratePanel";
// import { userGroups } from "../../utils/Commons";
// import Loading from "../Elements/Loading";

// const AdminComponent = ({ user }) => {
//   const [isModerator, setIsModerator] = useState(false);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const checkModeratorAccess = () => {
//       if (!user) {
//         setLoading(false);
//         return;
//       }

//       const groups = user.user_claims
//         ?.filter((claim) => claim.typ === "groups")
//         .map((claim) => claim.val) || [];

//       const isModerator = groups.some((group) => group.includes(userGroups.Moderator));
//       setIsModerator(isModerator);
//       setLoading(false);
//     };

//     checkModeratorAccess();
//   }, [user]);

//   if (loading) {
//     return <Loading />;
//   }

//   if (isModerator) {
//     return <ModeratePanel accessToken={user?.access_token} />;
//   }

//   return <div>No Access</div>;
// };

// export default AdminComponent;
