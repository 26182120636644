export const dictionaryCountries = {
    D: "DEU",
    RKS: "Kosovo",
    PSE: "Palestine",
    ZIM : "Zimbabwe",
    UNO : "United National Organization",
    UNK: "UNK"
  };


  export const userGroups = {
    Moderator: "Moderator",
    Contributor: "Contributor",
    Everyone: "Everyone",
  };


  export const imageStatus = {
    Pending: "Pending",
    Approved: "Approved",
    Declined: "Declined",
  };
