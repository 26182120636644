import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Tabs, Pagination, Radio, Alert, Button } from 'antd';
import { CommonAdminUrl } from '../../services/ServiceEndpoints';
import './style.css';
import { Image } from "antd";
import { createErrorElement } from '../../utils/Utils';
import { imageStatus } from '../../utils/Commons';
import Loading from '../Elements/Loading';
import BackButton from '../Elements/Back';

const { TabPane } = Tabs;

export default function ModeratePanel({ accessToken }) {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  const userName = localStorage.getItem("userName");
  const activeTabRef = useRef(activeTab);

  const fetchDocuments = async (status) => {
    setLoading(true);
    try {
      setError(null);
      setDocuments([]);
      setLoading(true);
      const response = await axios.get(
        `${CommonAdminUrl.GetImagesByStatus}?status=${status}&pageNumber=${currentPage}&pageSize=${pageSize}`
        , {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        });
      setDocuments(response.data);
      const total = response.headers.get('x-total-count');
      setTotalDocuments(total)
      setLoading(false);
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } catch (err) {
      setError(createErrorElement(err, "fetchDocuments"));
      setLoading(false);
    }
  };

  useEffect(() => {
    setDocuments([]);
    featchByCurrentTab();
  }, [activeTab, currentPage, pageSize]);

  const handleTabChange = (key) => {
    activeTabRef.current = key;
    setActiveTab(key);
    setCurrentPage(1);
  };

  const handleStatusChange = async (id, status, imageId) => {
    try {
      setError(null);

      const specimenStatusDto = {
        Id: id,
        ImageStatus: parseInt(status),
        ImageId: imageId,
        ModerateBy: userName
      };

      axios
        .put(CommonAdminUrl.UpdateImageStatus, specimenStatusDto, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(() => {
          const updatedDocuments = documents.map((doc) =>
            doc.Id === id && doc.ImageId === imageId
              ? { ...doc, ImageStatus: parseInt(status) }
              : doc
          );

          const updatedDocument = updatedDocuments.find(
            (doc) => doc.Id === id && doc.ImageId === imageId
          );

          if (updatedDocument) {
            const shouldRemoveDocument =
              (activeTabRef.current === '1' && status !== imageStatus.Pending) ||
              (activeTabRef.current === '2' && status !== imageStatus.Approved) ||
              (activeTabRef.current === '3' && status !== imageStatus.Declined);

            if (shouldRemoveDocument) {
              const newDocuments = updatedDocuments?.filter(
                (doc) => !(doc.Id === id && doc.ImageId === imageId)
              );
              setDocuments(newDocuments);
              setTotalDocuments((prevTotal) => prevTotal - 1);

              if (newDocuments.length === 0 && totalDocuments > 0) {
                featchByCurrentTab();
              }
            } else {
              setDocuments(updatedDocuments);
            }
          } else {
            featchByCurrentTab();
          }
        })
        .catch((err) => {
          console.error("Error saving status:", err);
          setError("Error saving status: " + (err.message || "Unknown error"));
        });
    } catch (error) {
      console.error("Exception occurred while handling status change:", error);
      setError(createErrorElement(error, "handleStatusChange"));
    }
  };

  const featchByCurrentTab = () => {
    if (activeTabRef.current === '1') {
      fetchDocuments(imageStatus.Pending);
    } else if (activeTabRef.current === '2') {
      fetchDocuments(imageStatus.Approved);
    } else if (activeTabRef.current === '3') {
      fetchDocuments(imageStatus.Declined);
    }
  }

  const displayDocuments = (docs) => docs.map((doc, index) => (
    <div key={`${doc.Id}-${index}`} className="card" style={{ minHeight: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <h3>
        {doc.Classification.CountryCode} - {doc.Classification.DocumentType}{' '}
        {doc.Classification.DocumentVersion}
        {doc.Classification.State ? ` - ${doc.Classification.State}` : ''}
        {doc.DataOrigin === 0 ? ' - Front' : doc.DataOrigin === 1 ? ' - Back' : doc.DataOrigin === 2 ? ' - Data' : ''}
      </h3>
      <p style={{ fontSize: '12px' }}>Document Id {doc.Id}</p>
      <p style={{ fontSize: '12px' }}>Image Id {doc.ImageId}</p>
      <div className="image-container" style={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {doc.Uri ? (
          <Image.PreviewGroup>
            <Image
              width={250}
              src={doc.Uri}
              alt="Document Image"
              onContextMenu={(e) => e.preventDefault()}
            />
          </Image.PreviewGroup>
        ) : (
          <p>No image available</p>
        )}
      </div>
      <p>{doc.description}</p>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginTop: '10px' }}>
        <Radio.Group
          value={doc.ImageStatus}
          onChange={(e) => handleStatusChange(doc.Id, e.target.value, doc.ImageId)}
          style={{ fontSize: '16px' }}
        >
          <Radio value={2} style={{ marginRight: '10px' }}>Approved</Radio>
          <Radio value={3}>Decline</Radio>
          <Radio value={1} style={{ marginRight: '10px' }}>Pending</Radio>
        </Radio.Group>
      </div>
    </div>
  ));

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  // TODO - need to refact
  const handleBatchApproval = async () => {
    const updatedDocuments = [...documents];
  
    for (const doc of documents) {
      try {
        setError(null);
  
        const specimenStatusDto = {
          Id: doc.Id,
          ImageStatus: 2,
          ImageId: doc.ImageId,
          ModerateBy: userName
        };
  
        await axios.put(CommonAdminUrl.UpdateImageStatus, specimenStatusDto, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const index = updatedDocuments.findIndex((d) => d.Id === doc.Id && d.ImageId === doc.ImageId);
        if (index > -1) {
          updatedDocuments[index] = { ...updatedDocuments[index], ImageStatus: doc.status };
  
          const shouldRemoveDocument =
            (activeTabRef.current === '1' && doc.status !== imageStatus.Pending) ||
            (activeTabRef.current === '2' && doc.status !== imageStatus.Approved) ||
            (activeTabRef.current === '3' && doc.status !== imageStatus.Declined);
  
          if (shouldRemoveDocument) {
            updatedDocuments.splice(index, 1);
          }
        }
  
      } catch (error) {
        console.error(`Error updating document with ID ${doc.Id}:`, error);
        setError(`Error saving status for document ID ${doc.Id}: ${error.message || "Unknown error"}`);
      }
    }
  
    setDocuments(updatedDocuments);
    setTotalDocuments(updatedDocuments.length); 
  
    if (updatedDocuments.length === 0 && totalDocuments > 0) {
      featchByCurrentTab();
    }
  };
  
  
  if (error) return <Alert message={error} type="error" showIcon style={{ margin: "15px" }} />;

  return (
    <div style={{ height: 'calc(100vh - 64px)' }}>
      <BackButton />
      <div style={{
        textAlign: 'left', paddingLeft: '20px', paddingBottom: '20px', paddingTop: '10px',
        borderBottom: '2px solid #e0e0e0'
      }}>
        <h4 style={{ fontSize: '24px', color: '#4a90e2', margin: 0 }}>Review and Approve Images</h4>
        <p style={{ fontSize: '14px', color: '#757575', marginTop: '10px' }}>Use the tabs below to review, approve, or decline images.</p>
      </div>

     
      <div style={{ paddingLeft: '2%', paddingRight: '2%', paddingBottom: '2%' }}>
    
        {/* Tabs for Pending, Approved, Declined */}
        <Tabs
          defaultActiveKey="1"
          value={activeTab}
          onChange={handleTabChange}
        >
          <TabPane tab="Pending" key="1" style={{ height: 'auto' }}>
            {loading ? (
              <div><Loading textAlign={"left"} /></div>
            ) : (
              <div>
                 {activeTab == "1" ?
                <Button type="primary" style={{marginBottom: "10px", fontSize: "14px"}} 
                onClick={handleBatchApproval}>Batch Approval (10 docs)</Button>
              : null
              }
              <div className="grid-container">
              {displayDocuments(documents)}</div>
              </div>
              
            )}
            {documents?.length === 0 && !loading ? 'No data' : ''}
          </TabPane>
          <TabPane tab="Approved" key="2" style={{ height: 'auto' }}>
            {loading ? (
              <div><Loading textAlign={"left"} /></div>
            ) : (
              <div className="grid-container">{displayDocuments(documents)}</div>
            )}
            {documents?.length === 0 && !loading ? 'No data' : ''}
          </TabPane>
          <TabPane tab="Declined" key="3" style={{ height: 'auto' }}>
            {loading ? (
              <div><Loading textAlign={"left"} /></div>
            ) : (
              <div className="grid-container">{displayDocuments(documents)}</div>
            )}
            {documents?.length === 0 && !loading ? 'No data' : ''}
          </TabPane>
         
        </Tabs>
           
        {
          activeTab != "4" && <Pagination
            current={currentPage}
            total={totalDocuments}
            pageSize={pageSize}
            onChange={onPageChange}
            onShowSizeChange={onPageSizeChange}
            style={{ marginTop: '20px', textAlign: 'right' }}
            showSizeChanger
            pageSizeOptions={['10']}
          />
        }

      </div>

    </div>
  );
}
