import React from "react";
import { Carousel, Image } from "antd";
import "./CarouselImage.css";
import imageNotFound from "../../assets/noImgFound.jpg";

export const CarouselImages = ({ header, image }) => {
  return (
    <div className="carousel-container">
      <h5 className="carousel-header">{header}</h5>
      <div className="carousel-content" style={{ textAlign: "center" }}>
        {image?.length > 0 && image[0] != null ? (
          <Image.PreviewGroup>
            <Carousel autoplay effect="fade" dots={false}>
              {image.map((url, index) => (
                <div key={index} className="carousel-image-wrapper" style={{ textAlign: "center" }}>
                  <Image
                    style={{ width: "80%", height: "auto", marginLeft: "10%" }}
                    onContextMenu={(e) => e.preventDefault()}
                    src={url}
                    fallback={imageNotFound}
                    onError={(e) => {
                      e.target.style.width = "150px";
                      e.target.style.height = "auto";
                    }}
                  />

                </div>
              ))}
            </Carousel>
          </Image.PreviewGroup>
        ) : (
          <div className="carousel-image-wrapper">
            <Image
              style={{ alignContent: "center", width: "350px", height: "auto" }}
              onContextMenu={(e) => e.preventDefault()}
              src={imageNotFound}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CarouselImages;
