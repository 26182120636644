import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import Loading from '../components/Elements/Loading';

export const Login = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState || authState.isPending || authState.isAuthenticated) {
      return;
    }

    oktaAuth.signInWithRedirect().catch((err) => {
      console.error('Error during login:', err);
    });
  }, [oktaAuth, authState]);

  return authState?.isPending ? <Loading /> : null;
};
